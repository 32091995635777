/*
 * @Descripttion: 组件公用的变量导出
 * @Author: 张明尧
 * @Date: 2021-02-22 15:46:28
 * @LastEditTime: 2021-03-17 16:08:17
 * 
 */
const product_hxmk_img = {
  hxmk1: require('@/assets/ktdn/cp/1.png'),
  hxmk2: require('@/assets/ktdn/cp/2.png'),
  hxmk3: require('@/assets/ktdn/cp/3.png'),
  hxmk4: require('@/assets/ktdn/cp/4.png'),
  hxmk5: require('@/assets/ktdn/cp/5.png'),
  hxmk6: require('@/assets/ktdn/cp/6.png'),
  hxmk7: require('@/assets/ktdn/cp/7.png'),
  hxmk8: require('@/assets/ktdn/cp/8.png'),
  hxmk9: require('@/assets/ktdn/cp/9.png'),
};

const Datas = {
  // 产品中的核心模块数据
  product_hxmk_datas: [
    {
      title: '企业营销（CRM）',
      img: product_hxmk_img.hxmk1,
      content: '全面的客户管理，加速商机转化特有的销售四连环，助您成交大客户',
    },
    {
      title: '企业办公（OA）',
      img: product_hxmk_img.hxmk2,
      content: '保障组织管理制度落地，提升执行力建立以目标为核心的工作体系',
    },
    {
      title: '企业项目（PM）',
      img: product_hxmk_img.hxmk3,
      content: '规范项目立项、执行流程，加强项目执行任务过程管理',
    },
    {
      title: '企业人力（HR）',
      width: '3.1rem',
      img: product_hxmk_img.hxmk4,
      content: '企业人才全周期管理，考勤方案自定义，灵活便捷，个性化薪酬方案',
    },
    {
      title: '企业知识（KMS）',
      img: product_hxmk_img.hxmk5,
      content: '搭建私有的线上企业大学，提升全员工作能力，综合学习能力',
    },
    {
      title: '企业绩效（KPI）',
      width: '1.7rem',
      img: product_hxmk_img.hxmk6,
      content: 'OKR与BSC完美的结合，持续提升个人、部门和组织的绩效',
    },
    
    {
      title: '企业文化（EC）',
      width: '108px',
      height: '108px',
      img: product_hxmk_img.hxmk7,
      content: '全方位的企业文化宣传，助您提升团队软技能，打造完美团队',
    },
    {
      title: '移动端（APP）',
      img: product_hxmk_img.hxmk8,
      content: '随时随地审批工作、查看业务数据，在线沟通，让您的工作更轻松',
    },
    {
      title: 'JEPaaS平台',
      width: '1.9rem',
      img: product_hxmk_img.hxmk9,
      content: '一套完整的软件开发平台，为企业信息化建设提供一揽子解决方案',
    },
  ],
  // 产品优势
  product_cpys_datas: [
    {
      title: '管理思想',
      colorTop: '#0358FD',
      colorBottom: '#4FCDFD',
      contents: [
        '我们是一套企业经营管理思想，而不仅仅是一款应用系统',
        '工具只能提高效率，只有思想才能提升能力',
        '企业管理要进入隧道，必须要有规范',
      ],
    },
    {
      title: '一站式',
      colorTop: '#0358FD',
      colorBottom: '#FCCE5C',
      contents: [
        '涵盖企业经营管理的各项领域，一站式搞定企业信息化',
        '全程无需切换不同工具，对接企业多角色人员',
        '多个业务环节统一管理，数据互相打通，消除信息孤岛',
      ],
    },
    {
      title: '智能化',
      colorTop: '#0358FD',
      colorBottom: '#E6484A',
      contents: [
        '新一代数字化公办平台',
        '网页+移动+桌面端全平台覆盖，使用便捷',
        '业界领先的系统设计，持续稳定高性能运转',
      ],
    },
    {
      title: 'JEPaaS平台支撑',
      colorTop: '#FF4738',
      colorBottom: '#FCCE5C',
      contents: [
        '历经十年研发而成，时间快成本低',
        '支持各类企业的产业级管理应用，满足多种业务',
        'JEPaaS平台，低代码灵活定制，靠谱',
      ],
    },
    {
      title: '敏捷的开发团队',
      colorTop: '#4FCDFD',
      colorBottom: '#FCCE5C',
      contents: [
        '参与讨论业务需求，把客户的业务融入到开发中',
        '核心功能，两到三周提供最小可用版本',
        '快速迭代，时间周期短',
      ],
    },
    {
      title: '敏锐的产品视角和精湛的技术',
      colorTop: '#FCCE5C',
      colorBottom: '#1C68FD',
      contents: [
        '能够站在客户的角度，分析问题，发掘客户真正的需求',
        '满足客户需求，根据市场价值帮助客户提供信息化解决方案',
        '整合资源，全局把控，确保客户的想法转变为“可用的软件”',
      ],
    },
  ]
};

export default Datas;