<!--
 * @Descripttion: 
 * @Author: 张明尧
 * @Date: 2021-02-23 14:56:50
 * @LastEditTime: 2021-03-17 16:22:01
-->
<template>
  <div class="product-div">
    <div class="product-main">
      <div class="product-div-title text-center margin-top-60">
        核心模块
      </div>
      <div class="product-div-content text-center margin-top-30">
        涵盖企业经营管理的各项领域，快速提升企业管理水平
      </div>
      <div>
        <div class="product-div-content-dis">
            <div class="product-div-content-dis_list" v-for="(hxmk, _hxmk) in hxmk_datas" :key="`product${_hxmk}`" :span="7">
              <div class="product-card">
                <div :class="hxmk.height && 'product-card-img-div-img'" class="product-card-img-div">
                  <img :style="hxmk.width && `width: ${hxmk.width}`" :src="hxmk.img" alt="">
                </div>
                <div class="product-card-title margin-top-20 text-center">
                  {{hxmk.title}}
                </div>
                <div class="product-card-content margin-top-30 text-center">
                  {{hxmk.content}}
                </div>
              </div>
          </div>
        </div>
      </div>
      <div class="product-div-title text-center margin-top-100">
        产品菜单地图
      </div>
      <div class="product-div-content text-center margin-top-30">
        涵盖企业经营管理的各项领域，快速提升企业管理水平
      </div>
      <div class="cpcddt-img-div">
        <img class="margin-top-30" src="../../assets/ktdn/cp/10.svg" alt="">
        <div class="product-div-title text-center margin-top-80">
          产品架构地图
        </div>
        <img class="margin-top-50" src="../../assets/ktdn/cp/11.svg" alt="">
      </div>
      <div class="product-div-title text-center margin-top-100">
        产品优势
      </div>
      <div class="product-div-content text-center margin-top-30">
        涵盖企业经营管理的各项领域，快速提升企业管理水平
      </div>
      <div class="product-cpys-div">
        <div class="product-card-cpys-every-main">
          <div class="margin-top-60 product-card-cpys-every" v-for="(cpys, _cpys) in cpys_datas" :key="_cpys" :span="12">
            <div class="product-card-cpys">
              <div class="product-card-cpys-title">
                <div class="product-card-cpys-title-yuan">
                  <div :style="cpys.colorTop && `background: ${cpys.colorTop}`" class="cpys-top"></div>
                  <div :style="cpys.colorBottom && `background: ${cpys.colorBottom}`" class="cpys-bottom"></div>
                  <div class="cpys-border"></div>
                </div>
                {{cpys.title}}
              </div>
              <div class="product-card-cpys-content">
                <div class="product-card-cpys-list textOmit" v-for="(cpys_content, _cpys_content) in cpys.contents" :key="_cpys_content">
                  {{cpys_content}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Datas from './js/common'
export default {
  data() {
    return {
      hxmk_datas: Datas.product_hxmk_datas,
      cpys_datas: Datas.product_cpys_datas,
    }
  }
}
</script>

<style lang="scss" scoped>
  .product-div {
    .text-center {
      text-align: center;
    }
    .textOmit {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
      display: inline-block;
      vertical-align: middle;
    }
    .product-main {
      width: 1300px;
      margin: 0 auto;
      min-height: 100vh;
      color: #3f3f3f;
      .product-div-title {
        font-size: 40px;
        font-weight: 600;
      }
      .product-div-content-dis {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        .product-div-content-dis_list {
          width: 30%;
          margin-top: 60px;
        }
      }
      .product-div-content {
        font-size: 18px;
      }
      .product-cpys-div {
        padding-bottom: 200px;
      }
      .product-card-cpys-every-main {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 1400px;
        .product-card-cpys-every {
          width: 48%;
          margin-right: 10px;
        }
      }
      .product-card-cpys {
        height: 230px;
        background: #FFFFFF;
        box-shadow: 1px 0px 20px 0px rgba(103, 103, 103, 0.2);
        border-radius: 20px;
        padding-top: 30px;
        padding-left: 80px;
        .product-card-cpys-title {
          font-size: 22px;
          font-weight: 500;
          position: relative;
          .product-card-cpys-title-yuan {
            position: absolute;
            top: 5px;
            left: -60px;
            .cpys-border {
              position: absolute;
              left: 60px;
              bottom: 0px;
              width: 50px;
              height: 4px;
              border-radius: 2px;
              background: #427CF4;
            }
            .cpys-top {
              width: 35px;
              height: 35px;
              border-radius: 50%;
              position: relative;
              z-index: 2;
            }
            .cpys-bottom {
              width: 30px;
              height: 30px;
              border-radius: 50%;
              position: absolute;
              left: 15px;
              top: -8px;
              z-index: 1;
            }
          }
        } 
         .product-card-cpys-content {
            margin-top: 20px;
            font-size: 15px;
            .product-card-cpys-list {
              position: relative;
              padding-left: 20px;
              line-height: 2.2;
              &::before {
                content: ' ';
                position: absolute;
                left: 5px;
                top: 50%;
                margin-top: -2.5px;
                width: 5px;
                height: 5px;
                background-color: #3f3f3f;
                border-radius: 50%;
              }
            }
          }
      }
      .product-card {
        height: 400px;
        background: #FFFFFF;
        box-shadow: 1px 0px 20px 0px rgba(180, 179, 179, 0.5);
        border-radius: 20px;
        padding-top: 30px;
        .product-card-title {
          font-size: 22px;
          font-weight: 600;
          position: relative;
        }
        .product-card-content {
          font-size: 16px;
          padding: 0 30px;
          line-height: 2;
        }
        .product-card-img-div-img {
          img {
            width: 120px!important;
            height: 120px!important;
          }
        }
        .product-card-img-div {
          text-align: center;
          margin-top: 20px;
          img {
            width: 120px;
            height: auto;
          }
        }
      }
      .cpcddt-img-div {
        text-align: center;
        img {
          width: 1200px;
          height: auto;
        }
      }
    }
  }
</style>